import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import postersfullImg from "../../images/projectsfull/projectflow.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>EntityOne project workflow posters</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="EntityOne project workflow posters"
              projectfulldescription="EntityOne needed a way to inform their employees of the workflow in a more uniform way. I set out to make posters that explain the whole flow, from sales to development to support."
              projectclient="EntityOne"
              projectrole="Graphic Design"
              projectdate="2019 - 2020"
              projectfullimg={<div className="project--img--inner"><img data-src={postersfullImg} className="project--img lazyload" alt="EntityOne project workflow posters" /></div>}
              projectnextlink="/projects/ipvdiergeneeskunde"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;